<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 7.4C1 5.15979 1 4.03968 1.43597 3.18404C1.81947 2.43139 2.43139 1.81947 3.18404 1.43597C4.03968 1 5.15979 1 7.4 1H10.6C12.8402 1 13.9603 1 14.816 1.43597C15.5686 1.81947 16.1805 2.43139 16.564 3.18404C17 4.03968 17 5.15979 17 7.4V10.6C17 12.8402 17 13.9603 16.564 14.816C16.1805 15.5686 15.5686 16.1805 14.816 16.564C13.9603 17 12.8402 17 10.6 17H7.4C5.15979 17 4.03968 17 3.18404 16.564C2.43139 16.1805 1.81947 15.5686 1.43597 14.816C1 13.9603 1 12.8402 1 10.6V7.4Z" fill="#CCFF00" />
    <path d="M0.990471 2.95704C0.730583 3.4671 0.61318 4.03416 0.556157 4.73209C0.499995 5.41948 0.499997 6.27641 0.5 7.37737V7.4V10.6V10.6226C0.499997 11.7236 0.499995 12.5805 0.556157 13.2679C0.61318 13.9658 0.730583 14.5329 0.990471 15.043C1.4219 15.8897 2.11031 16.5781 2.95704 17.0095C3.4671 17.2694 4.03416 17.3868 4.73209 17.4438C5.41948 17.5 6.27641 17.5 7.37736 17.5H7.4H10.6H10.6226C11.7236 17.5 12.5805 17.5 13.2679 17.4438C13.9658 17.3868 14.5329 17.2694 15.043 17.0095C15.8897 16.5781 16.5781 15.8897 17.0095 15.043C17.2694 14.5329 17.3868 13.9658 17.4438 13.2679C17.5 12.5805 17.5 11.7236 17.5 10.6226V10.6V7.4V7.37736C17.5 6.27641 17.5 5.41948 17.4438 4.73209C17.3868 4.03416 17.2694 3.4671 17.0095 2.95704C16.5781 2.11031 15.8897 1.4219 15.043 0.990471C14.5329 0.730583 13.9658 0.61318 13.2679 0.556157C12.5805 0.499995 11.7236 0.499997 10.6226 0.5H10.6H7.4H7.37737C6.27641 0.499997 5.41948 0.499995 4.73209 0.556157C4.03416 0.61318 3.4671 0.730583 2.95704 0.990471C2.11031 1.4219 1.4219 2.11031 0.990471 2.95704Z" stroke="#0D0B24" stroke-opacity="0.1" />
    <path d="M12.7352 3.83729C13.1655 3.72254 13.3806 3.66516 13.5505 3.71562C13.6993 3.75983 13.8264 3.85754 13.9075 3.98992C14 4.141 14 4.36368 14 4.80905V5.08447C14 5.36107 14 5.49937 13.9521 5.61498C13.9098 5.71699 13.8413 5.80607 13.7537 5.87322C13.6543 5.94933 13.5207 5.98496 13.2534 6.05623L10.5506 6.77699C10.1202 6.89174 9.90507 6.94912 9.73526 6.89866C9.58646 6.85445 9.45928 6.75675 9.37822 6.62436C9.28571 6.47328 9.28571 6.2506 9.28571 5.80523V5.52981C9.28571 5.25321 9.28571 5.11491 9.33365 4.99931C9.37595 4.89729 9.44438 4.80822 9.53205 4.74106C9.6314 4.66496 9.76503 4.62932 10.0323 4.55805L12.7352 3.83729Z" fill="#121212" />
    <path d="M6.53561 5.38577C6.90928 5.28655 7.10666 5.24008 7.26474 5.28705C7.41354 5.33126 7.54072 5.42896 7.62178 5.56135C7.71429 5.71243 7.71429 5.93511 7.71429 6.38048V6.6559C7.71429 6.9325 7.71429 7.0708 7.66635 7.1864C7.62405 7.28842 7.55562 7.37749 7.46795 7.44465C7.3686 7.52075 7.23497 7.55639 6.96771 7.62766L6.53571 7.74286V8.14876C6.53571 8.42536 6.53571 8.56366 6.48778 8.67926C6.44548 8.78128 6.37705 8.87035 6.28938 8.93751C6.19002 9.01361 6.05639 9.04925 5.78913 9.12052L5.44342 9.21271C5.01309 9.32746 4.79793 9.38484 4.62811 9.33438C4.47932 9.29017 4.35214 9.19246 4.27108 9.06008C4.18808 8.92452 4.17955 8.73133 4.17867 8.37137C3.805 8.47059 3.60762 8.51706 3.44954 8.47009C3.30074 8.42588 3.17357 8.32817 3.09251 8.19579C3 8.04471 3 7.82203 3 7.37666V7.10124C3 6.82464 3 6.68634 3.04793 6.57073C3.09023 6.46872 3.15867 6.37965 3.24634 6.31249C3.34569 6.23639 3.47932 6.20075 3.74658 6.12948L4.17857 6.01428V5.60838C4.17857 5.33178 4.17857 5.19348 4.22651 5.07788C4.2688 4.97586 4.33724 4.88679 4.42491 4.81963C4.52426 4.74353 4.65789 4.70789 4.92515 4.63663L5.27086 4.54444C5.70119 4.42968 5.91636 4.3723 6.08617 4.42276C6.23497 4.46697 6.36215 4.56468 6.44321 4.69706C6.52621 4.83262 6.53474 5.02581 6.53561 5.38577Z" fill="#121212" />
    <path d="M13.9924 9.51506C13.9768 9.24039 13.969 9.10305 13.8734 8.96028C13.7997 8.85032 13.6458 8.73932 13.5182 8.70415C13.3525 8.6585 13.1898 8.70264 12.8642 8.79093L5.69379 10.7355C5.36853 10.8237 5.20591 10.8679 5.08602 10.9909C4.99373 11.0856 4.91688 11.2593 4.90884 11.3913C4.89839 11.5628 4.9609 11.685 5.08591 11.9294C5.86637 13.4554 7.45406 14.5 9.28572 14.5C11.8894 14.5 14 12.3894 14 9.78573C14 9.69489 13.9974 9.60464 13.9924 9.51506Z" fill="#121212" />
    <path d="M9.63989 13.6986C9.54455 13.7071 9.46705 13.6251 9.4821 13.5305C9.7208 12.0315 11.0195 10.8857 12.5857 10.8857C12.6807 10.8857 12.7747 10.89 12.8676 10.8982C12.9631 10.9067 13.0236 11.0021 12.9917 11.0925C12.4907 12.5133 11.1959 13.5595 9.63989 13.6986Z" fill="white" />
  </svg>
</template>
